import * as React from 'react';

const check = (props: any) => {
  const { colorPrimary } = props;

  return (
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        transform="translate(5.000000, 4.999967)"
        stroke={colorPrimary}
        strokeWidth="5"
      >
        <path d="M90,40.8858213 L90,45.0258218 C89.9946,54.729623 86.8522496,64.1719741 81.0418489,71.943925 C75.2318982,79.716326 67.0648473,85.4020766 57.7592962,88.153377 C48.453295,90.9046773 38.5076639,90.5743773 29.4051078,87.2115269 C20.3025517,83.8486764 12.5309158,77.6332757 7.24931013,69.4927747 C1.9677045,61.3518238 -0.5409513,51.7222726 0.0975492762,42.0391715 C0.736009352,32.3562953 4.4874348,23.1392142 10.7922506,15.7625883 C17.0970663,8.38596245 25.6175473,3.24498183 35.0828484,1.10640158 C44.5481946,-1.03213368 54.4513458,-0.0537435591 63.3149968,3.89577191"></path>
        <polyline points="90 9.02581752 44.9999946 54.0708229 31.499993 40.5708213"></polyline>
      </g>
    </g>
  );
};
export default check;
