import * as React from 'react';

const leaf = (props: any) => {
  const { colorPrimary } = props;

  return (
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M92.6002976,10.7128463 L92.056217,5 L86.6154109,6.81360202 C77.2908449,10.053351 67.3334831,11.0475338 57.5524386,9.71536524 C47.2745133,8.12035341 36.7545955,9.5377076 27.265285,13.7959698 C17.044186,18.1482242 9.72285431,27.3809524 7.81440335,38.324937 C6.59647462,45.2832227 7.20576715,52.4385042 9.58266532,59.0906801 C11.1131391,55.6361653 12.9015706,52.3018015 14.9327913,49.115869 C19.5402496,42.3368005 25.9244819,36.956477 33.3861918,33.5642317 C40.57459,30.28218 48.4317756,28.7293765 56.3282573,29.0302267 L56.3282573,29.0302267 C48.8109742,30.1795934 41.6154109,32.8817899 35.1997938,36.9647355 C32.1052194,38.9091399 29.271272,41.2402902 26.7665444,43.9017632 C24.3710622,46.5619707 22.2589303,49.4642527 20.4642774,52.5617128 C17.0933778,59.0363211 14.6948255,65.9722621 13.3458895,73.1460957 C11.8918924,80.343079 11.1026765,87.6585034 10.9882069,95 L20.056217,95 C20.4824079,88.415869 21.3763509,81.8703877 22.7312799,75.4130982 C29.0219062,78.8099784 36.0764548,80.5424008 43.2249827,80.4458438 C49.8921303,80.441263 56.4906875,79.0999675 62.6305243,76.5012594 C97.1343026,61.7657431 92.6002976,12.7531486 92.6002976,10.7128463 Z"
        fill={colorPrimary}
        fillRule="nonzero"
      ></path>
    </g>
  );
};
export default leaf;
