import * as React from 'react';

const times = (props: any) => {
  const { colorPrimary } = props;

  return (
    <>
      <path
        d="M79.438 75.381L53.535 49.478l25.902-25.902a2.5 2.5 0 10-3.535-3.535L50 45.943 24.097 20.04a2.5 2.5 0 10-3.535 3.535l25.902 25.902L20.562 75.38a2.5 2.5 0 103.535 3.536L50 53.014l25.902 25.902a2.5 2.5 0 103.536-3.535"
        fill={colorPrimary}
        fillRule="evenodd"
      />
    </>
  );
};
export default times;
