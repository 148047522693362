import * as React from 'react';
import Styled from './Icon.style';
import IconRoot from './list';

interface IconProps {
  icon: string;
  colorPrimary?: string;
  colorSecondary?: string;
  size?: string | number;
  style?: React.CSSProperties;
  className?: string;
}

export const Icon: React.FC<IconProps> = (props) => {
  const {
    icon,
    size = '100%',
    style,
    colorPrimary = '#0B2641',
    colorSecondary = '#25B3EB',
    className,
  } = props;

  return (
    <Styled.Icon
      width={size}
      height={size}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 100 100"
      style={style}
    >
      <IconRoot
        icon={icon}
        colorPrimary={colorPrimary}
        colorSecondary={colorSecondary}
      />
    </Styled.Icon>
  );
};
export default Icon;
