/// <reference types="webpack-env" />
import promise from 'redux-promise-middleware';
import monitorReducersEnhancer from './enhancers/monitorReducers';
import loggerMiddleware from './middleware/logger';
import rootReducer from './reducers';
import { routerMiddleware } from 'connected-react-router';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: any;
  }
}

function configureAppStore(preloadedState) {
  const middlewares = [
    promise,
    routerMiddleware(history),
    ...getDefaultMiddleware({ serializableCheck: false }),
  ];

  if (process.env.NODE_ENV === 'development') {
    middlewares.push(loggerMiddleware);
  }

  const store = configureStore({
    reducer: rootReducer,
    middleware: middlewares,
    preloadedState,
    enhancers: [monitorReducersEnhancer],
  });

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(rootReducer));
  }

  return store;
}

const store = configureAppStore(undefined);

export default store;
