import React from 'react';
import {
  Input,
  Button,
  Notification,
  Typography,
  Popup,
  AddImage,
  Checkbox,
  Loader,
  Select,
} from '../../../components';
import { Row, Col } from 'react-styled-flexboxgrid';
import { useFormik, Field, FormikProvider } from 'formik';
import * as Yup from 'yup';
import Styled from './Marketplace.style';
import * as _ from 'lodash';
import store from 'store2';
import { useNavigate } from 'react-router-dom';
interface UpdateBannerProps {
  setUpdate: Function;
  onDelete: Function;
  data: any;
  list: Function;
  paramId: string;
  loading?: boolean;
  error?: string;
  categories: any;
  bannerTags: any;
}

export const UpdateBanner: React.FC<UpdateBannerProps> = (props) => {
  const {
    data,
    setUpdate,
    list,
    onDelete,
    paramId,
    loading,
    error,
    categories,
    bannerTags,
  } = props;

  const [popup, setPopup] = React.useState(false);

  const navigate = useNavigate();
  const UpdateBannerSchema = Yup.object().shape({
    title: Yup.string().max(64, 'Maximum allowed is 64 characters.'),
    description: Yup.string(),
    buttonText: Yup.string().max(24, 'Maximum allowed is 24 characters.'),
    type: Yup.string(),
    buttonPath: Yup.string(),
    published: Yup.boolean(),
    logo: Yup.mixed().test(
      'fileSize',
      'Image is too big! Max image size is 2MB',
      (value) => {
        if (value) {
          return value && value.size <= 2000000;
        } else {
          return true;
        }
      }
    ),
    productDescription: Yup.string(),
    productUniqueness: Yup.string(),
    categoryId: Yup.string(),
  });
  const onImageChange = (event) => {
    setImage(event.target.files[0]);
    formik.setFieldValue('logo', event.currentTarget.files[0]);
    setFilename(event.currentTarget.files[0].name);
  };

  const renderOptionsCat = () => {
    return (
      categories &&
      categories.children.map((el, index) => {
        return (
          <React.Fragment key={`option-${el.parent_id}-${el.id}-${index}`}>
            <option value={parseInt(el.id)}>{el.title}</option>
            {el.children &&
              el.children.map((elem) => {
                return (
                  <React.Fragment
                    key={`suboption-${elem.parent_id}-${elem.id}`}
                  >
                    <option value={parseInt(elem.id)}>
                      &nbsp;&nbsp;{elem.title}
                    </option>
                    {elem.children &&
                      elem.children.map((element) => {
                        return (
                          <option
                            key={`suboeption-${element.parent_id}-${element.id}`}
                            value={parseInt(element.id)}
                          >
                            &nbsp;&nbsp;&nbsp;{element.title}
                          </option>
                        );
                      })}
                  </React.Fragment>
                );
              })}
          </React.Fragment>
        );
      })
    );
  };

  const findList = data && data.find((o) => o.id === parseInt(paramId));

  const title = _.get(findList, 'title');

  const description = _.get(findList, 'description');
  const buttonText = _.get(findList, 'buttonText');
  const buttonPath = _.get(findList, 'buttonPath');
  const published = _.get(findList, 'published');
  const imageUrl = _.get(findList, 'imageURL');

  const productDescription = _.get(findList, 'productDescription');
  const productUniqueness = _.get(findList, 'productUniqueness');
  const categoryId = _.get(findList, 'category.id');
  const id = _.get(findList, 'id');

  const [bannerImage, setImage] = React.useState(null);
  const [filename, setFilename] = React.useState('');
  const tagIds = _.get(findList, 'tags');
  const renderTags = tagIds && tagIds.map((e) => parseInt(e.id));
  const imgUrl = bannerImage ? `${URL.createObjectURL(bannerImage)}` : imageUrl;
  const formik = useFormik({
    initialValues: {
      title: title ? title : '',
      description: description ? description : '',
      buttonText: 'Find out more',
      buttonPath: buttonPath ? buttonPath : '',
      logo: '',
      productDescription: productDescription ? productDescription : '',
      productUniqueness: productUniqueness ? productUniqueness : '',
      published: published ? published : '',
      categoryId: categoryId ? categoryId : '',
      tagIds: renderTags ? renderTags : [],
    },
    validationSchema: UpdateBannerSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const numberArray = formik.values.tagIds.toString();
      const amendedFields = {
        ...values,
        tagIds: numberArray,
      };
      setUpdate(
        id,
        amendedFields,
        () => {
          setSubmitting(false);
          setImage(null);
          resetForm();
        },
        () => {
          setSubmitting(false);
          console.log('failed');
        }
      );
      setTimeout(() => {
        list();
      }, 1000);
      setSubmitting(false);
    },
  });
  console.log(formik.values);
  if (loading) {
    return <Loader />;
  }

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
        <Row>
          <Col md={12} xs={12} sm={12}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '16px',
              }}
            >
              <Button
                theme="WHITE"
                style={{ width: '160px', marginRight: '8px' }}
                onClick={() => navigate('/myshop')}
              >
                Back to MyShop
              </Button>
              <Typography
                size="2"
                text="Edit Banner"
                style={{ marginBottom: '0px' }}
              />
            </div>
          </Col>

          <Col md={8} xs={12} sm={12}>
            <Row>
              <Col sm={12} xs={12} md={6}>
                <Input
                  id="title"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="text"
                  label="Title"
                  placeholder="Title"
                  value={formik.values.title}
                />
              </Col>
              <Col sm={12} xs={12} md={6}>
                <Select
                  value={formik.values.categoryId}
                  onChange={formik.handleChange}
                  name="categoryId"
                  id="categoryId"
                  placeholder="Select category"
                  label="Category"
                >
                  {renderOptionsCat()}
                </Select>
              </Col>
              <Col md={12} sm={12} xs={12} style={{ marginTop: '16px' }}>
                <div
                  style={{
                    padding: '16px',
                    border: '1px solid #e2e8f0',
                    borderRadius: '6px',
                    marginBottom: '16px',
                  }}
                >
                  <div
                    style={{
                      marginTop: '-26px',
                      background: 'white',
                      width: '60px',
                      padding: '0px 16px',
                      marginBottom: '8px',
                      fontSize: '14px',
                      fontWeight: 600,
                    }}
                  >
                    Tags
                  </div>
                  {bannerTags &&
                    bannerTags.map((el, index) => {
                      return (
                        <label key={index} style={{ marginRight: '16px' }}>
                          <Field
                            type="checkbox"
                            name="selectedCheckboxes"
                            value={el.id}
                            checked={formik.values.tagIds.includes(el.id)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                formik.setFieldValue('tagIds', [
                                  ...formik.values.tagIds,
                                  el.id,
                                ]);
                              } else {
                                formik.setFieldValue(
                                  'tagIds',
                                  formik.values.tagIds.filter(
                                    (id) => id !== el.id
                                  )
                                );
                              }
                            }}
                          />
                          {el.title}
                        </label>
                      );
                    })}
                </div>
              </Col>
              <Col sm={12} xs={12} md={6}>
                <Input
                  id="buttonPath"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="text"
                  label="Button path"
                  placeholder="Button path"
                  value={formik.values.buttonPath}
                />
              </Col>
              <Col sm={12} xs={12} md={6}>
                <AddImage
                  onClearClick={() => setImage(null)}
                  onImageChange={() => onImageChange}
                  name="logo"
                  label="Add Image"
                  disclaimer="Maximun file size 2MB.Recommended aspect ratio is: 5:4. (A minimum resolution of 300x250 pixels)."
                  filename={filename ? filename : 'Please select a image'}
                />
              </Col>
              <Col sm={12} xs={12} md={6}>
                <Input
                  id="description"
                  type="text"
                  label="Description"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Description"
                  value={formik.values.description}
                />
              </Col>
              <Col sm={12} xs={12} md={6}>
                <Checkbox
                  onChange={formik.handleChange}
                  name="published"
                  id="published"
                  checked={formik.values.published}
                  value={formik.values.published}
                  boxSize={40}
                >
                  Published?
                </Checkbox>
              </Col>
              <Col md={12} xs={12} sm={12}>
                <Typography
                  style={{
                    fontSize: '14px',
                    width: '700px',
                    marginBottom: '8px',
                  }}
                  size="p"
                  text="These last two fields are not required but they will affect how your appear in the search, so for a much better experience please fill them up."
                />
              </Col>
              <Col sm={12} xs={12} md={6}>
                <Input
                  id="productDescription"
                  type="text"
                  icon="pen"
                  label="Search term(s)"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Search term(s)"
                  value={formik.values.productDescription}
                />
              </Col>
              <Col sm={12} xs={12} md={6}>
                <Input
                  id="productUniqueness"
                  type="text"
                  label="Product Sustainability story"
                  icon="pen"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  style={{ marginBottom: '16px' }}
                  placeholder="Product Sustainability story"
                  value={formik.values.productUniqueness}
                />
              </Col>
              {error ? (
                <Col md={12} sm={12} xs={12}>
                  <Notification isClose title={error} theme="Error" />
                </Col>
              ) : null}
              <Col
                md={12}
                sm={12}
                xs={12}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  style={{ marginRight: '16px' }}
                  theme="WHITE"
                  onClick={(e) => {
                    setPopup(!popup);
                    e.preventDefault();
                  }}
                >
                  Delete
                </Button>
                <Button type="submit" disabled={formik.isSubmitting || loading}>
                  Update
                </Button>
              </Col>
            </Row>
          </Col>
          <Col md={4} xs={12} sm={12}>
            <Row>
              <Col md={12} xs={12} sm={12}>
                <Styled.PreviewBanner>
                  <Styled.ImgWrap
                    style={{
                      backgroundImage: `url(${imgUrl})`,
                    }}
                  />
                  <Styled.CopyWrap>
                    <Typography
                      size="4"
                      text={formik.values.title ? formik.values.title : title}
                      style={{
                        marginBottom: '8px',
                        fontSize: '20px',
                        minHeight: '52px',
                      }}
                    />
                    <Styled.ShortDescription>
                      {formik.values.description
                        ? formik.values.description
                        : description}
                    </Styled.ShortDescription>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: 'auto',
                      }}
                    ></div>
                  </Styled.CopyWrap>
                </Styled.PreviewBanner>
              </Col>
            </Row>

            <Popup open={popup} handleClick={() => setPopup(!popup)}>
              <Typography
                size="3"
                text="Are you sure you want to delete your banner?"
                style={{ marginBottom: '12px' }}
              />
              <Typography
                size="p"
                style={{ fontSize: '15px  ' }}
                text="All your data will be lost"
              />
              <div>
                <Button
                  style={{ marginRight: '8px' }}
                  onClick={() => {
                    onDelete(id);
                    setPopup(!popup);
                    store('type', '');
                    setTimeout(() => {
                      list();
                    }, 500);
                    navigate('/myshop');
                  }}
                >
                  Yes
                </Button>
                <Button theme="WHITE" onClick={() => setPopup(!popup)}>
                  No
                </Button>
              </div>
            </Popup>
          </Col>
        </Row>
      </form>
    </FormikProvider>
  );
};

export default UpdateBanner;
