import styled from 'styled-components';

const Mask = styled.div`
  mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' fill='none'%3E%3Cpath fill='%23000' d='M100 0C20 0 0 20 0 100s20 100 100 100 100-20 100-100S180 0 100 0z'/%3E%3C/svg%3E");
  color: #475569;
  background-color: #e2e8f0;
  fill: #475569;
  height: 48px;
  width: 48px;
  mask-position: center;
  font-weight: 600;
  mask-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  mask-size: contain;
  &.green {
    color: #10b981;
    background-color: rgba(16, 185, 129, 0.1);
  }
  &.blue {
    color: #0ea5e9;
    background-color: rgba(14, 165, 233, 0.1);
  }
`;
export default {
  Mask,
};
