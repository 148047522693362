import React from 'react';
import {
  Input,
  Button,
  Typography,
  AddImage,
  Notification,
  Select,
  Checkbox,
} from '../../../components';
import { Row, Col } from 'react-styled-flexboxgrid';
import { useFormik, Field, FormikProvider } from 'formik';
import * as Yup from 'yup';
import Styled from './Marketplace.style';
import * as _ from 'lodash';

interface CreateBannerProps {
  onSubmit: Function;
  list: Function;
  loading?: boolean;
  error?: string;
  done?: boolean;
  user: any;
  bannerTags: any;
  categories: any;
}

export const CreateBanner: React.FC<CreateBannerProps> = (props) => {
  const { onSubmit, list, error, done, loading, bannerTags, categories } =
    props;

  const CreateBannerSchema = Yup.object().shape({
    title: Yup.string()
      .required('Required')
      .max(64, 'Maximum allowed is 64 characters.'),
    description: Yup.string().required('Required'),
    buttonPath: Yup.string().required('Please enter website URL'),
    logo: Yup.mixed()
      .required('Image is required')
      .test(
        'fileSize',
        'Image is too big! Max image size is 2MB',
        (value) => value && value.size <= 2000000
      ),
    productDescription: Yup.string(),
    productUniqueness: Yup.string(),
    categoryId: Yup.number(),
    tagIds: Yup.array(),
  });

  const renderOptionsCat = () => {
    return (
      categories &&
      categories.children.map((el, index) => {
        return (
          <React.Fragment key={`option-${el.parent_id}-${el.id}-${index}`}>
            <option value={parseInt(el.id)}>{el.title}</option>
            {el.children &&
              el.children.map((elem) => {
                return (
                  <React.Fragment
                    key={`suboption-${elem.parent_id}-${elem.id}`}
                  >
                    <option value={parseInt(elem.id)}>
                      &nbsp;&nbsp;{elem.title}
                    </option>
                    {elem.children &&
                      elem.children.map((element) => {
                        return (
                          <option
                            key={`suboeption-${element.parent_id}-${element.id}`}
                            value={parseInt(element.id)}
                          >
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{element.title}
                          </option>
                        );
                      })}
                  </React.Fragment>
                );
              })}
          </React.Fragment>
        );
      })
    );
  };

  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
      theme: 'lightTheme',
      buttonPath: '',
      buttonText: 'Find out more',
      logo: '',
      productDescription: '',
      productUniqueness: '',
      categoryId: '',
      tagIds: [],
    },
    validationSchema: CreateBannerSchema,
    onSubmit: (values, { setSubmitting }) => {
      const numberArray = formik.values.tagIds.toString();
      const amendedFields = {
        ...values,
        tagIds: numberArray,
      };
      onSubmit(
        amendedFields,
        () => {
          setSubmitting(false);
          setImage(null);
          setTimeout(() => {
            list();
          }, 500);
        },
        () => {
          setSubmitting(false);
          console.log('failed');
        }
      );
      setSubmitting(false);
    },
  });

  const onImageChange = (event) => {
    setImage(event.target.files[0]);
    formik.setFieldValue('logo', event.currentTarget.files[0]);
    setFilename(event.currentTarget.files[0].name);
  };
  const [bannerImage, setImage] = React.useState(null);
  const [filename, setFilename] = React.useState('');

  const imgUrl = bannerImage
    ? `${URL.createObjectURL(bannerImage)}`
    : 'https://cdn.getwaterfit.co.uk/static/files/images/develop/component/free-products.jpg';

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
        <Row>
          <Col md={12} xs={12} sm={12}>
            <Typography text="Create advert" size="2" />
          </Col>
          <Col md={8} sm={12} xs={12}>
            <Row>
              <Col sm={12} xs={12} md={6}>
                <Input
                  id="title"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="text"
                  label="Title"
                  placeholder="Title"
                  style={{ marginBottom: '8px' }}
                  value={formik.values.title}
                  error={
                    formik.errors.title && formik.touched.title
                      ? formik.errors.title
                      : undefined
                  }
                />
              </Col>
              <Col md={6} sm={12} xs={12}>
                <Select
                  value={formik.values.categoryId}
                  onChange={formik.handleChange}
                  name="categoryId"
                  id="categoryId"
                  placeholder="Select category"
                  label="Category"
                >
                  {renderOptionsCat()}
                </Select>
              </Col>
              <Col md={12} sm={12} xs={12} style={{ marginTop: '16px' }}>
                <div
                  style={{
                    padding: '16px',
                    border: '1px solid #e2e8f0',
                    borderRadius: '6px',
                    marginBottom: '16px',
                  }}
                >
                  <div
                    style={{
                      marginTop: '-26px',
                      background: 'white',
                      width: '60px',
                      padding: '0px 16px',
                      marginBottom: '8px',
                      fontSize: '14px',
                      fontWeight: 600,
                    }}
                  >
                    Tags
                  </div>
                  {bannerTags &&
                    bannerTags.map((el, index) => {
                      return (
                        <label key={index} style={{ marginRight: '16px' }}>
                          <Field
                            type="checkbox"
                            name="tagIds"
                            id="tagIds"
                            value={`${el.id}`}
                          />
                          {el.title}
                        </label>
                      );
                    })}
                </div>
              </Col>
              <Col sm={12} xs={12} md={6}>
                <Input
                  id="buttonPath"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="text"
                  label="Button path"
                  placeholder="Button path"
                  style={{ marginBottom: '8px' }}
                  value={formik.values.buttonPath}
                  error={
                    formik.errors.buttonPath && formik.touched.buttonPath
                      ? formik.errors.buttonPath
                      : undefined
                  }
                />
              </Col>
              <Col sm={12} xs={12} md={6}>
                <AddImage
                  onClearClick={() => {
                    setImage(null);
                    formik.setFieldValue('logo', '');
                  }}
                  onImageChange={() => onImageChange}
                  name="logo"
                  label="Add Image"
                  disclaimer="Maximun file size 2MB.Recommended aspect ratio is: 5:4. (A minimum resolution of 300x250 pixels)."
                  filename={filename ? filename : 'Please select a image'}
                  error={
                    formik.errors.logo && formik.touched.logo
                      ? formik.errors.logo
                      : undefined
                  }
                />
              </Col>
              <Col sm={12} xs={12} md={6}>
                <Input
                  id="description"
                  type="text"
                  label="Description"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  style={{ marginBottom: '8px' }}
                  placeholder="Description"
                  value={formik.values.description}
                  error={
                    formik.errors.description && formik.touched.description
                      ? formik.errors.description
                      : undefined
                  }
                />
              </Col>
              <Col md={12} xs={12} sm={12}>
                <Typography
                  style={{
                    fontSize: '14px',
                    width: '700px',
                    marginBottom: '8px',
                    fontWeight: 500,
                  }}
                  size="p"
                  text="These last two fields are not required but they will affect how your appear in the search, so for a much better experience please fill them up."
                />
              </Col>
              <Col sm={12} xs={12} md={6}>
                <Input
                  id="productDescription"
                  type="text"
                  icon="pen"
                  label="Search term(s)"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  style={{ marginBottom: '8px' }}
                  placeholder="Search term(s)"
                  value={formik.values.productDescription}
                />
              </Col>
              <Col sm={12} xs={12} md={6}>
                <Input
                  id="productUniqueness"
                  type="text"
                  label="Product Sustainability story"
                  icon="pen"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  style={{ marginBottom: '8px' }}
                  placeholder="Product Sustainability story"
                  value={formik.values.productUniqueness}
                />
              </Col>
              {error ? (
                <Col md={12} sm={12} xs={12}>
                  <Notification isClose title={error} theme="Error" />
                </Col>
              ) : null}
              {done ? (
                <Col md={12} sm={12} xs={12}>
                  <Notification
                    isClose
                    title={'Success, your stall has been created.'}
                    theme="Success"
                  />
                </Col>
              ) : null}
              <Col
                sm={12}
                xs={12}
                md={12}
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'flex-end',
                  marginTop: '8px',
                }}
              >
                <Button disabled={formik.isSubmitting || loading} type="submit">
                  Create advert
                </Button>
              </Col>
            </Row>
          </Col>
          <Col md={4} sm={12} xs={12}>
            <Styled.PreviewBanner>
              <Styled.ImgWrap
                style={{
                  backgroundImage: `url(${imgUrl})`,
                }}
              />
              <Styled.CopyWrap>
                <Typography
                  size="4"
                  text={
                    formik.values.title ? formik.values.title : 'Sample title'
                  }
                  style={{
                    marginBottom: '8px',
                    fontSize: '20px',
                    minHeight: '52px',
                  }}
                />
                <Styled.ShortDescription>
                  {formik.values.description
                    ? formik.values.description
                    : 'Sample description'}
                </Styled.ShortDescription>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 'auto',
                  }}
                ></div>
              </Styled.CopyWrap>
            </Styled.PreviewBanner>
          </Col>
        </Row>
      </form>
    </FormikProvider>
  );
};

export default CreateBanner;
