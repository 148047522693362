import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { AppReducer } from '../interfaces';
import * as _ from 'lodash';

const POSTS_URL = '/config.json';

const initialState: AppReducer = {
  status: { loading: false, error: null, done: false },
  data: null,
};

export const getConfigApi = createAsyncThunk('config/getConfig', async () => {
  const response = await axios.get(POSTS_URL);
  return response.data;
});

const appReducer = createSlice({
  name: 'config',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getConfigApi.pending, (state, action) => {
        state.status = {
          loading: true,
          error: null,
          done: false,
        };
        state.data = null;
      })
      .addCase(getConfigApi.fulfilled, (state, action) => {
        state.status = {
          loading: false,
          error: null,
          done: true,
        };
        state.data = action.payload.navElements;
      })
      .addCase(getConfigApi.rejected, (state, action) => {
        state.status = {
          loading: true,
          error: true,
          done: true,
        };
        state.data = null;
      });
  },
});

export const getData = (state: any) => state.app.data;
export const getStatus = (state: any) => state.app.status;

export default appReducer.reducer;
